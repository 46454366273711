import * as React from "react";
import { memo, useMemo } from "react";
import "./condo-shield.css";

const CondoShield = memo(
  ({
    unsplashmHRfLhgABo,
    associationManagementAndM,
    condoShield,
    yourPartnerInSeamlessAsso,
    cSLogoFull1,
    propMinHeight,
    propHeight,
    propHeight1,
    propWidth,
    href,
  }) => {
    const frameDiv1Style = useMemo(() => {
      return {
        minHeight: propMinHeight,
        height: propHeight,
      };
    }, [propMinHeight, propHeight]);

    const cSLogoFull1IconStyle = useMemo(() => {
      return {
        height: propHeight1,
        width: propWidth,
      };
    }, [propHeight1, propWidth]);

    return (
      <div className="condo-shield">
        <img
          className="unsplashm-hrflhgabo-icon"
          loading="eager"
          alt=""
          src={unsplashmHRfLhgABo}
        />
        <div className="frame-container">
          <div className="frame-div">
            <div className="association-management-and-mai-parent">
              <div className="association-management-and">
                {associationManagementAndM}
              </div>
              <div className="condo-shield1">{condoShield}</div>
            </div>
            <div className="your-partner-in">{yourPartnerInSeamlessAsso}</div>
          </div>
          <div
            className="button-group"
            style={frameDiv1Style}
            onClick={() => window.open(href)}
          >
            <button className="button16">
              <div className="base8">
                <img className="masked-icon16" alt="" src="/masked-icon6.svg" />
                <div className="button17">Go there now</div>
                <img
                  className="masked-icon17"
                  alt=""
                  src="/masked-icon-1.svg"
                />
              </div>
            </button>
            <div className="cs-logo-full-1-wrapper">
              <img
                className="cs-logo-full-1-icon"
                loading="eager"
                alt=""
                src={cSLogoFull1}
                style={cSLogoFull1IconStyle}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CondoShield;
