import * as React from "react";
import { memo } from "react";
import "./core-values.css";

const CoreValues = memo(() => {
  return (
    <section className="core-values">
      <div className="core-values-text">
        <h1 className="core-values1">Core Values</h1>
        <h3 className="evergreen-brands-operates">
          Evergreen Brands operates on a strong foundation of six core values,
          around which our foundation and culture thrive.
        </h3>
      </div>
      <div className="features">
        <div className="group-a">
          <div className="be-nimble">
            <img
              className="be-nimble-icon"
              loading="eager"
              alt=""
              src="/be-nimble@2x.png"
            />
            <div className="we-embrace-change">
              We embrace change and foster an environment of flexibility. We
              strive never to stagnate, so that we're always offering you
              innovative solutions.
            </div>
          </div>
          <div className="be-engaged">
            <img
              className="be-engaged-icon"
              loading="eager"
              alt=""
              src="/be-engaged@2x.png"
            />
            <div className="its-about-being">
              It's about being fully present in the moment, undistracted, and
              dedicated in delivering to you our best service.
            </div>
          </div>
          <div className="help-first">
            <img
              className="help-first-icon"
              loading="eager"
              alt=""
              src="/help-first@2x.png"
            />
            <div className="respect-for-others">
              Respect for others and extending support come naturally to us.
              Noticing and doing what's right is embedded in our assistance.
            </div>
          </div>
        </div>
        <div className="group-b">
          <div className="for-each-other">
            <img
              className="for-each-other1"
              loading="eager"
              alt=""
              src="/for-each-other@2x.png"
            />
            <div className="we-believe-in">
              We believe in the power of teamwork — the strength of every pillar
              contributes to the structure. We work as one, having each other's
              backs, to provide the best for you.
            </div>
          </div>
          <div className="see-it-through">
            <img
              className="see-it-through1"
              loading="eager"
              alt=""
              src="/see-it-through@2x.png"
            />
            <div className="were-devoted-to">
              We're devoted to staying on target, focusing on primary
              objectives. Our determination to meet your needs is unwavering
              from initiation to completion of every task.
            </div>
          </div>
          <div className="have-grit">
            <img
              className="have-grit-icon"
              loading="eager"
              alt=""
              src="/have-grit@2x.png"
            />
            <div className="were-committed-to">
              We're committed to displaying resilience and perseverance. This
              translates to hard work, discipline, and a relentless drive to
              achieve results for you.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CoreValues;
