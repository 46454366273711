import * as React from "react";
import TopHeader from "../components/top-header";
import CompanyCards from "../components/company-cards";
import CoreValues from "../components/core-values";
import Footer from "../components/footer";
import "./index.css";
import {
  motion,
  useInView,
  useAnimation,
  AnimatePresence,
} from "framer-motion";
import { Helmet } from "react-helmet-async";

const EvergreenBrandscom = () => {
  const ref = React.useRef();
  const isInView = useInView(ref, { once: true });
  const [index, setIndex] = React.useState(0);
  const controls = useAnimation();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 200 },
    exit: { opacity: 0, y: -200 },
  };

  // React.useEffect(() => {
  //   if (isInView) {
  //     controls.start("visible");
  //     setInterval(() => {
  //       setIndex((prev) => prev + 1);
  //     }, 1000);
  //   }
  // }, [isInView]);

  return (
    <>
      <Helmet>
        <title>Evergreen Brands | Change the World</title>
        <meta
          name="description"
          content="Join Evergreen Brands to change the world - uniting powerful solutions for your success with our commitment to global philanthropy."
        />
      </Helmet>
      <div className="evergreenbrandscom">
        <TopHeader />
        <section className="hero">
          <h1 className="change-the-world-container">
            <span>{`Change `}</span>
            <span className="the-world">the World.</span>
          </h1>

          <div className="empowering-your-success">
            Empowering your success with diversified brands - simplifying
            operations, enriching lifestyles, and sparking positive global
            change. Your journey, our mission.
          </div>
        </section>

        <CompanyCards />
        <CoreValues />
        <Footer />
      </div>
    </>
  );
};

export default EvergreenBrandscom;
