import * as React from "react";
import { memo, useState, useCallback } from "react";
import ContactModal from "./contact-modal";
import PortalPopup from "./portal-popup";
import "./top-header.css";
import ContactModalThankYou from "./contact-modal-thank-you";

const TopHeader = memo(() => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isThankYouOpen, setThankYouOpen] = useState(false);

  const openContactModal = useCallback(() => {
    setContactModalOpen(true);
  }, []);

  const closeContactModal = useCallback(() => {
    setContactModalOpen(false);
  }, []);

  const openThankYouModal = useCallback(() => {
    setThankYouOpen(true);
  }, []);

  const closeThankYouModal = useCallback(() => {
    setThankYouOpen(false);
  }, []);

  return (
    <>
      <header className="top-header">
        <div className="top-container">
          <div className="parent-company-frame">
            <img
              className="evergreenbrands-logo-name-1-icon"
              loading="eager"
              alt=""
              src="/evergreenbrands-logo-name-1@2x.png"
            />
          </div>
          <div className="navigation-menu" onClick={openContactModal}>
            <div className="buttongroup">
              <div className="button2">
                <div className="base1">
                  <img className="masked-icon2" alt="" src="/masked-icon.svg" />
                  <div className="button3">Our Mission</div>
                  <img
                    className="masked-icon3"
                    alt=""
                    src="/masked-icon1.svg"
                  />
                </div>
              </div>
              <div className="vertical" />
              <div className="button4">
                <div className="base2">
                  <img className="masked-icon4" alt="" src="/masked-icon.svg" />
                  <div className="button5">About</div>
                  <img
                    className="masked-icon5"
                    alt=""
                    src="/masked-icon1.svg"
                  />
                </div>
              </div>
              <div className="vertical1" />
              <div className="button6">
                <div className="base3">
                  <img className="masked-icon6" alt="" src="/masked-icon.svg" />
                  <div className="button7">Careers</div>
                  <img
                    className="masked-icon7"
                    alt=""
                    src="/masked-icon1.svg"
                  />
                </div>
              </div>
              <div className="vertical2" />
              <div className="button8">
                <div className="base4">
                  <img className="masked-icon8" alt="" src="/masked-icon.svg" />
                  <div className="button9">Contact Us</div>
                  <img
                    className="masked-icon9"
                    alt=""
                    src="/masked-icon3.svg"
                  />
                </div>
              </div>
              <div className="vertical3" />
              <div className="button10">
                <div className="base5">
                  <img
                    className="masked-icon10"
                    alt=""
                    src="/masked-icon4.svg"
                  />
                  <div className="button11">Label</div>
                  <img
                    className="masked-icon11"
                    alt=""
                    src="/masked-icon5.svg"
                  />
                </div>
              </div>
              <div className="vertical4" />
              <div className="button12">
                <div className="base6">
                  <img
                    className="masked-icon12"
                    alt=""
                    src="/masked-icon4.svg"
                  />
                  <div className="button13">Label</div>
                  <img
                    className="masked-icon13"
                    alt=""
                    src="/masked-icon5.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isContactModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeContactModal}
        >
          <ContactModal
            onClose={closeContactModal}
            openThankYouModal={openThankYouModal}
          />
        </PortalPopup>
      )}
      {isThankYouOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setThankYouOpen(false)}
        >
          <ContactModalThankYou onClose={closeThankYouModal} />
        </PortalPopup>
      )}
    </>
  );
});

export default TopHeader;
