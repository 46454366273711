import React, { useState, useCallback } from "react";
import { memo } from "react";
import "./footer.css";
import PortalPopup from "./portal-popup";
import ContactModal from "./contact-modal";
import ContactModalThankYou from "./contact-modal-thank-you";

const Footer = memo(() => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isThankYouOpen, setThankYouOpen] = useState(false);

  const openContactModal = useCallback(() => {
    setContactModalOpen(true);
  }, []);

  const closeContactModal = useCallback(() => {
    setContactModalOpen(false);
  }, []);

  const openThankYouModal = useCallback(() => {
    setThankYouOpen(true);
  }, []);

  const closeThankYouModal = useCallback(() => {
    setThankYouOpen(false);
  }, []);
  return (
    <>
      <footer className="footer">
        <div className="copyright-information">
          © 2024, Evergreen Brands. All Rights Reserved.
        </div>
        <div className="navigation-menu1">
          <div className="buttongroup1">
            <div className="button20">
              <div className="base10">
                <img className="masked-icon20" alt="" src="/masked-icon.svg" />
                <div className="button21">Our Mission</div>
                <img className="masked-icon21" alt="" src="/masked-icon1.svg" />
              </div>
            </div>
            <div className="vertical5" />
            <div className="button22">
              <div className="base11">
                <img className="masked-icon22" alt="" src="/masked-icon.svg" />
                <div className="button23">About</div>
                <img className="masked-icon23" alt="" src="/masked-icon1.svg" />
              </div>
            </div>
            <div className="vertical6" />
            <div className="button24">
              <div className="base12">
                <img className="masked-icon24" alt="" src="/masked-icon.svg" />
                <div className="button25">Careers</div>
                <img className="masked-icon25" alt="" src="/masked-icon1.svg" />
              </div>
            </div>
            <div className="vertical7" />
            <div className="button26" onClick={openContactModal}>
              <div className="base13">
                <img className="masked-icon26" alt="" src="/masked-icon.svg" />
                <div className="button27">Contact Us</div>
                <img className="masked-icon27" alt="" src="/masked-icon1.svg" />
              </div>
            </div>
            <div className="vertical8" />
            <div className="button28">
              <div className="base14">
                <img className="masked-icon28" alt="" src="/masked-icon4.svg" />
                <div className="button29">Label</div>
                <img className="masked-icon29" alt="" src="/masked-icon5.svg" />
              </div>
            </div>
            <div className="vertical9" />
            <div className="button30">
              <div className="base15">
                <img className="masked-icon30" alt="" src="/masked-icon4.svg" />
                <div className="button31">Label</div>
                <img className="masked-icon31" alt="" src="/masked-icon5.svg" />
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isContactModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeContactModal}
        >
          <ContactModal
            onClose={closeContactModal}
            openThankYouModal={openThankYouModal}
          />
        </PortalPopup>
      )}
      {isThankYouOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setThankYouOpen(false)}
        >
          <ContactModalThankYou onClose={closeThankYouModal} />
        </PortalPopup>
      )}
    </>
  );
});

export default Footer;
