import * as React from "react";
import { memo } from "react";
import Transblue from "./transblue";
import CondoShield from "./condo-shield";
import "./company-cards.css";

const CompanyCards = memo(() => {
  return (
    <section className="company-cards">
      <div className="project-card">
        <div className="evergreen-brands">
          <img
            className="unsplash9anj7qwy-2g-icon"
            loading="eager"
            alt=""
            src="/unsplash9anj7qwy2g@2x.png"
          />
          <div className="frame-group1">
            <div className="company-name-list">
              <div className="brands-section-title">
                <div className="embracing-change-the">
                  Embracing Change the World
                </div>
                <div className="evergreen-brands1">Evergreen Brands</div>
              </div>
              <div className="the-parent-company">
                The parent company with one focus: make significant impact a
                reality.
              </div>
            </div>
            <div className="company-cards-frame">
              <img
                className="button-group-frame"
                loading="eager"
                alt=""
                src="/frame-291@2x.png"
              />
            </div>
          </div>
        </div>
        <CondoShield
          unsplashmHRfLhgABo="/unsplashm-hrflhgabo@2x.png"
          associationManagementAndM="Association Management and Maintenance"
          condoShield="Condo Shield"
          yourPartnerInSeamlessAsso="Your partner in seamless association management and maintenance for all HOAs."
          cSLogoFull1="/cs-logo-full-1@2x.png"
          href="https://condoshield.org"
        />
        <CondoShield
          unsplashmHRfLhgABo="/unsplash8v4yxxt3mq@2x.png"
          associationManagementAndM="Sweeten The World, One Jar at a Time"
          condoShield="Honey4Hope"
          yourPartnerInSeamlessAsso="Every drop, every jar, resonates with our dedication to quality and charitable impact."
          cSLogoFull1="/honey4hope-hexlogo-1@2x.png"
          propMinHeight="unset"
          propHeight="48px"
          propHeight1="60px"
          propWidth="69.4px"
          href="https://honey4hope.org"
        />
        <Transblue
          unsplashC5DLhUkEWfM="/unsplashgwe0dlvd9e0@2x.png"
          nationalMultiSiteFacility="Empowering Generosity and Philanthropy"
          transblue="mBridge Global"
          efficientlyCoordinatingNa="Unlocking your philanthropic potential, catalyzing generosity to enhance our world."
          iconFrames="/frame-291-2@2x.png"
          propMinHeight="unset"
          propHeight="48px"
          href="https://mbridge.global"
        />

        <div className="wescott-consulting-group">
          <img
            className="unsplashsavqflrm4do-icon"
            loading="eager"
            alt=""
            src="/unsplashsavqflrm4do@2x.png"
          />
          <div className="frame-parent1">
            <div className="frame-parent2">
              <div className="expertise-from-blue-collar-exp-parent">
                <div className="expertise-from-blue-collar">
                  Expertise From Blue-Collar Experts
                </div>
                <div className="wescott-consulting-group1">
                  Wescott Consulting Group
                </div>
              </div>
              <div className="drawing-from-expertise">
                Drawing from expertise to provide practical solutions that
                elevate your business.
              </div>
            </div>
            <div className="button-container">
              <button className="button18">
                <div className="base9">
                  <img
                    className="masked-icon18"
                    alt=""
                    src="/masked-icon6.svg"
                  />
                  <div
                    className="button19"
                    onClick={() =>
                      window.open("https://Wescottconsultinggroup.com")
                    }
                  >
                    Go there now
                  </div>
                  <img
                    className="masked-icon19"
                    alt=""
                    src="/masked-icon-1.svg"
                  />
                </div>
              </button>
              <button
                className="wcg-logo-1-wrapper"
                onClick={() =>
                  window.open("https://Wescottconsultinggroup.com")
                }
              >
                <img
                  className="wcg-logo-1-icon"
                  alt=""
                  src="/wcg-logo-1@2x.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CompanyCards;
